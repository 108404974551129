// Color variables
$color-white: #ffffff;
$color-black: #000000;
$color-curious-blue: #218ade;
$color-bright-navy-blue: #1c77bf;
$color-auro-metal-saurus: #6c7682;
$color-platinum: #e3e7eb;
$color-cultured: #f3f6f9;
$color-roman-silver: #7c858e;
$color-light-silver: #d9d9d9;
$color-pumpkin: #f97316;
$color-green: #27ae60;
$color-orange-red: #ff4d4f;
$color-lavender-gray: #bacad6;
$color-medium-slate-blue: #7772f8;

// Font size variables
$fontSize-xsm: 12px;
$fontSize-sm: 14px;
$fontSize-md: 16px;
$fontSize-lg: 26px;
$fontSize-xlg: 32px;

// Font family variables
$fontFamily-Inter-Regular: 'Inter-Regular', sans-serif;
$fontFamily-Inter-Medium: 'Inter-Medium', sans-serif;
$fontFamily-NotoSans-Regular: 'NotoSans-Regular', sans-serif;
$fontFamily-NotoSans-Medium: 'NotoSans-Medium', sans-serif;
$fontFamily-NotoSans-Bold: 'NotoSans-Bold', sans-serif;
$fontFamily-Roboto-Regular: 'Roboto-Regular', sans-serif;
$fontFamily-Roboto-Medium: 'Roboto-Medium', sans-serif;
$fontFamily-Roboto-Black: 'Roboto-Black', sans-serif;

// Media breakpoints (max-width)
$screen-maxWidth-sm: 576px;
$screen-maxWidth-md: 768px;
$screen-maxWidth-lg: 992px;
$screen-maxWidth-xl: 1200px;
$screen-maxWidth-xxl: 1600px;
