.text-editor {
  width: 100%;
  height: 160px;

  & .quill {
    height: 100%;
  }

  & .ql-container {
    height: calc(100% - 42px);
  }
}
