.events-list {
  padding: 32px 40px 62px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: $color-white;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px #14458D1A;

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    &-search {
      max-width: 638px;
    }

    &-event-type {
      display: flex;
      align-items: center;
      gap: 27px;

      &-title {
        font-family: $fontFamily-NotoSans-Regular;
        color: $color-auro-metal-saurus;
        white-space: nowrap;
      }

      &-select {
        width: 188px;
      }
    }
  }

  &__table {
    flex: 1;

    &-item {
      &-check-in svg {
        width: 20px;
        height: 20px;
        color: $color-auro-metal-saurus;

        &:hover {
          color: $color-curious-blue;
        }
      }
    }
  }
}

@media (max-width: $screen-maxWidth-md) {
  .events-list {
    &__filter {
      flex-direction: column;

      &-search {
        max-width: 100%;
      }

      &-event-type {
        width: 100%;

        &-select {
          width: 100%;
        }
      }
    }
  }
}
