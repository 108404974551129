.health-metrics {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    font-family: $fontFamily-Roboto-Medium;
    font-size: 20px;
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 80px;
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .health-metrics {
    &__container {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
  }
}
