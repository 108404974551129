.header {
  height: 80px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-curious-blue;

  &__nav {
    margin-left: 224px;
    gap: 38px;
    background: transparent;
    border: none;

    &::before {
      display: none;
    }

    & .ant-menu {
      &-title-content {
        font-family: $fontFamily-NotoSans-Medium;
        font-size: $fontSize-md;
        color: $color-white !important;
      }
  
      &-item {
        padding-inline: 0;
        padding: 9px 18px;
        border-radius: 2px !important;
        line-height: normal;

        &::after {
          display: none;
        }

        &-active {
          background: $color-bright-navy-blue !important;
        }

        &-selected {
          background: $color-bright-navy-blue !important;
        }
      }
    }
  }

  &__dropdown {
    &-container {
      display: flex;
      align-items: center;

      & * {
        font-family: $fontFamily-Inter-Medium;
        font-size: $fontSize-md;
        color: $color-white;
      }

      & .ant-space-item:nth-child(2) {
        height: 24px;
      }
    }
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .header {
    &__nav {
      margin-left: 0;
      gap: 10px;
    }
  }
}
