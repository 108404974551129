.login {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &__form {
    width: 100%;
    max-width: 648px;
    padding: 40px 56px 64px;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border-radius: 2px;
  }

  &__title {
    margin-bottom: 24px;
    font-family: $fontFamily-Roboto-Medium;
    font-size: $fontSize-lg;
  }
}
