.assessment-history {
  display: flex;
  flex-direction: column;
  gap: 27px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-family: $fontFamily-Roboto-Medium;
    font-size: 20px;
  }

  &__table {
    margin-bottom: 32px;
    
    & .ant-table {
      &-thead {
        & .ant-table-cell {
          background: transparent !important;
          border: none !important;
          font-family: $fontFamily-Inter-Regular;
          font-size: $fontSize-sm;
          color: $color-auro-metal-saurus;
        }
      }

      &-tbody {
        & .ant-table-row {
          &:nth-child(odd) {
            & .ant-table-cell {
              background: $color-cultured;
            }
          }
        }

        & .ant-table-cell {
          border: none !important;
          font-family: $fontFamily-Inter-Regular;
          font-size: $fontSize-sm;
        }
      }
    }
  }
}
