.event-details {
  margin-bottom: 11px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: $color-cultured;
  border-radius: 2px;

  & * {
    font-family: $fontFamily-Inter-Regular;
    font-size: $fontSize-sm;
  }

  &__container {
    max-width: 490px;
    display: flex;
    align-items: center;
    column-gap: 24px;
    row-gap: 8px;
    flex-wrap: wrap;
  }

  &__item {
    &-label {
      margin-right: 8px;
      color: $color-auro-metal-saurus;
    }
  }
}
