.messages {
  display: flex;
  flex-direction: column;

  &__container {
    flex: 1;
    margin-bottom: 46px;
    max-height: 1053px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: $color-light-silver;
    }
  }

  &__scroll-container {
    width: calc(100% - 5px);
  }

  &__block {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;

    &-date {
      margin-bottom: 8px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-cultured;
      border-radius: 24px;
      font-family: $fontFamily-Roboto-Regular;
      font-size: $fontSize-xsm;
      color: $color-roman-silver;
    }

    &-start-chat {
      margin-bottom: 8px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(39, 174, 96, 0.1);
      border-radius: 24px;
      font-family: $fontFamily-Roboto-Regular;
      font-size: $fontSize-xsm;
      color: $color-green;
    }

    &-end-chat {
      margin-bottom: 8px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 127, 0, 0.1);
      border-radius: 24px;
      font-family: $fontFamily-Roboto-Regular;
      font-size: $fontSize-xsm;
      color: $color-pumpkin;
    }
  }

  &__message {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 8px;

    &-avatar {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-medium-slate-blue;
      border-radius: 50%;
      font-family: $fontFamily-NotoSans-Bold;
      font-size: 12px;
      color: $color-white;
    }

    &-container {
      padding: 16px;
      max-width: 640px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: $color-cultured;
      border-radius: 8px;
    }

    &-username {
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-sm;
      line-height: 21px;
    }

    &-data {
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-sm;
      line-height: 21px;
      color: $color-auro-metal-saurus;
      word-break: break-word;

      & p {
        margin: 0;
      }

      & a {
        display: inline;
        color: $color-auro-metal-saurus;
      }
    }

    &-time {
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-sm;
      color: $color-auro-metal-saurus;
    }

    &.current-user-message {
      justify-content: flex-end;

      & .messages__message-container {
        background: $color-curious-blue;
      }

      & .messages__message-data {
        color: $color-white;

        & p {
          margin: 0;
        }

        & a {
          display: inline;
          color: $color-white;
        }
      }
    }
  }

  &__assessment {
    margin: 16px 0px 24px;
    padding: 16px;
    background: $color-cultured;
    border-radius: 8px;

    &-item {
      &-label {
        margin-bottom: 8px;
        font-family: $fontFamily-Roboto-Regular;
        font-size: $fontSize-md;
      }

      &-value {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        gap: 6px;
        font-family: $fontFamily-Roboto-Regular;
        font-size: $fontSize-md;
        color: $color-auro-metal-saurus;
      }
    }
  }

  &__video-call {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 8px;

    &-avatar {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-medium-slate-blue;
      border-radius: 50%;
      font-family: $fontFamily-NotoSans-Bold;
      font-size: 12px;
      color: $color-white;
    }

    &-container {
      padding: 16px;
      max-width: 640px;
      display: flex;
      align-items: center;
      background: $color-cultured;
      border-radius: 8px;
      gap: 10px;

      & path {
        fill: $color-curious-blue;
      }
    }

    &-username {
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-sm;
      line-height: 21px;
    }

    &-data {
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-sm;
      line-height: 21px;
      color: $color-auro-metal-saurus;
    }

    &-time {
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-sm;
      color: $color-auro-metal-saurus;
    }
  }

  &__call {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 8px;

    &-container {
      padding: 16px;
      max-width: 640px;
      display: flex;
      align-items: center;
      gap: 10px;
      background: $color-curious-blue;
      border-radius: 8px;
    }

    &-data {
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-sm;
      line-height: 21px;
      color: $color-white;
    }

    &-time {
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-sm;
      color: $color-auro-metal-saurus;
    }
  }

  &__lucy {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 8px;

    &-avatar {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-medium-slate-blue;
      border-radius: 50%;
      font-family: $fontFamily-NotoSans-Bold;
      font-size: 12px;
      color: $color-white;
    }

    &-container {
      padding: 16px;
      max-width: 640px;
      background: rgba(255, 127, 0, 0.1);
      border-radius: 8px;
      color: $color-pumpkin;
    }

    &-time {
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-sm;
      color: $color-auro-metal-saurus;
    }
  }

  &__footer {
    padding-top: 33px;
    display: flex;
    align-items: flex-start;
    gap: 31px;
    border-top: 1px solid $color-platinum;
  }
}
