.promis {
  &__title {
    margin-bottom: 10px;
    font-family: $fontFamily-Roboto-Medium;
    font-size: 20px;
  }

  &__description {
    margin-bottom: 24px;
    font-family: $fontFamily-Inter-Regular;
    font-size: $fontSize-sm;
    color: $color-auro-metal-saurus;
  }

  &__table {
    & .ant-table {
      &-thead {
        & .ant-table-cell {
          background: transparent !important;
          border: none !important;
          font-family: $fontFamily-Inter-Regular;
          font-size: $fontSize-sm;
          color: $color-auro-metal-saurus;
        }
      }

      &-tbody {
        & .ant-table-row {
          &:nth-child(odd) {
            & .ant-table-cell {
              background: $color-cultured;
            }
          }
        }

        & .ant-table-cell {
          border: none !important;
          font-family: $fontFamily-Inter-Regular;
          font-size: $fontSize-sm;
        }
      }
    }
  }
}
