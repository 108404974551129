.filter-radio {
  display: flex;
  align-items: center;
  gap: 22px;

  &__btn {
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 2px !important;
    border-inline-start-width: 1px;

    &::before {
      display: none !important;
    }

    &:hover {
      color: $color-curious-blue;
    }

    &.ant-radio-button-wrapper-checked {
      background: $color-curious-blue !important;
      border-color: $color-curious-blue !important;
    }
  }
}
