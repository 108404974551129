.time-editor {
  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &__sep {
    font-size: $fontSize-xlg;
  }

  &__item {
    height: 114px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-input {
      width: 66px;
      height: 68px;
      padding: 0;
      text-align: center;
      font-family: $fontFamily-Inter-Medium;
      font-size: $fontSize-xlg;
      line-height: 140%;
  
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &-btn {
      &-increase,
      &-decrease {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        cursor: pointer;
      }
      
      &-increase {
        border-bottom: 10px solid $color-cultured;
  
        &:hover {
          border-bottom: 10px solid $color-curious-blue;
        }
      }
  
      &-decrease {
        border-top: 10px solid $color-cultured;
  
        &:hover {
          border-top: 10px solid $color-curious-blue;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 18px;
  }
}
