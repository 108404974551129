.history {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: $color-white;

  &__title {
    font-family: $fontFamily-Inter-Medium;
    font-size: $fontSize-lg;
  }

  &__content {
    max-height: 450px;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-cultured;

    &-header {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 16px;

      & * {
        font-family: $fontFamily-Inter-Regular;
        color: $color-auro-metal-saurus;
      }
    }

    &-message {
      margin-bottom: 10px;
      font-family: $fontFamily-Roboto-Regular;

      &.note {
        & * {
          margin: revert;
          font-family: sans-serif;
          font-weight: revert;
          font-size: revert;
        }

        & blockquote {
          margin: 5px 0px;
          border-left: 4px solid #ccc;
          padding-left: 16px;
        }
      }
    }

    &-comment {
      font-family: $fontFamily-Roboto-Regular;
      color: $color-auro-metal-saurus;
    }
  }
}
