.info-panel {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

.info-panel-item {
  min-height: 109px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-white;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px #14458D1A;

  &__title {
    font-family: $fontFamily-NotoSans-Medium;
    font-size: $fontSize-md;
  }

  &__value {
    font-family: $fontFamily-NotoSans-Medium;
    font-size: $fontSize-xlg;
  }

  &-colored {
    background: $color-curious-blue;

    & .info-panel-item__title {
      color: $color-white;
    }
  
    & .info-panel-item__value {
      color: $color-white;
    }
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .info-panel {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $screen-maxWidth-md) {
  .info-panel {
    grid-template-columns: repeat(1, 1fr);
  }
}
