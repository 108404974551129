@import '../abstracts/variables';

.ant-layout {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-input {
  height: 48px;
  font-family: $fontFamily-NotoSans-Regular;
  border-radius: 3px;

  &:hover {
    border-color: $color-curious-blue;
  }

  &:focus {
    border-color: $color-curious-blue;
  }

  &::placeholder {
    color: $color-auro-metal-saurus;
  }

  &-search {
    & .ant-input {
      height: 48px;
      font-family: $fontFamily-NotoSans-Regular;
      border-radius: 1px;

      &:hover {
        border-color: $color-curious-blue;
      }

      &:focus {
        border-color: $color-curious-blue;
      }

      &::placeholder {
        color: $color-auro-metal-saurus;
      }
    }

    & .ant-input-search-button {
      width: 48px;
      height: 48px;
      border-start-end-radius: 1px !important;
      border-end-end-radius: 1px !important;

      &:hover {
        border-color: $color-curious-blue !important;
        color: $color-curious-blue !important;
      }

      & svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &-password {
    height: 48px;
    border-radius: 3px;

    &:hover {
      border-color: $color-curious-blue;
    }

    &.ant-input-affix-wrapper-focused {
      border-color: $color-curious-blue;
    }

    & .ant-input {
      height: auto;
    }
  }
}

.ant-select {
  height: 48px;

  &:hover {
    & .ant-select-selector {
      border-color: $color-curious-blue !important;
    }
  }

  &-focused {
    & .ant-select-selector {
      border-color: $color-curious-blue !important;
    }
  }

  &-selection-item {
    font-family: $fontFamily-NotoSans-Regular;
  }

  &-arrow {
    top: 18px !important;
    height: 24px !important;

    & path {
      fill: $color-black;
    }
  }
}

.ant-table {
  &-thead {
    & .ant-table-cell {
      background: $color-cultured !important;
      border-top: 1px solid $color-platinum;
      border-bottom: 2px solid $color-platinum !important;
      border-start-start-radius: 0 !important;
      border-start-end-radius: 0 !important;
      font-family: $fontFamily-Roboto-Black;
      color: $color-roman-silver !important;

      &::before {
        display: none;
      }

      & .ant-table-column-sorters {
        justify-content: flex-start;
        gap: 4px;

        & .anticon.active {
          color: $color-curious-blue !important;
        }
      }

      & .ant-table-column-title {
        flex: none;
      }
    }
  }

  &-tbody {
    & .ant-table-cell {
      border-bottom: 1px solid $color-platinum !important;
      font-family: $fontFamily-Roboto-Regular;
      font-size: $fontSize-xsm;
    }
  }
}

.ant-pagination {
  &-jump-prev {
    & .anticon-double-left {
      color: $color-curious-blue !important;
    }
  }

  &-jump-next {
    & .anticon-double-right {
      color: $color-curious-blue !important;
    }
  }

  &-item-active {
    border-color: $color-curious-blue !important;

    & a {
      color: $color-curious-blue !important;
    }
  }
}

.ant-menu {
  border-bottom: 1px solid $color-light-silver;

  &-item {
    &::after {
      left: 0 !important;
      width: 100%;
    }

    &-selected {
      & .ant-menu-title-content {
        color: $color-curious-blue;
      }

      &::after {
        border-bottom-color: $color-curious-blue !important;
      }
    }

    &-active {
      &::after {
        border-bottom-color: $color-curious-blue !important;
      }
    }
  }

  &-title-content {
    display: flex;
    align-items: center;
    font-family: $fontFamily-Roboto-Medium;
    font-size: $fontSize-sm;

    & .ant-badge {
      margin-left: 6px;
    }
  }
}
