.event {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;

  &__navigation {
    display: flex;
    align-items: center;

    & * {
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-xsm;
      color: $color-auro-metal-saurus !important;
    }

    &-btn {
      height: auto;
      padding: 0;
      margin-right: 4px;
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      box-shadow: none;

      & .ant-wave {
        display: none;
      }

      & .ant-btn-icon {
        transform: rotate(90deg);

        & path {
          fill: $color-black;
        }
      }

      & span {
        text-decoration: underline;
      }

      &:hover {
        & span {
          text-decoration: none;
        }
      }
    }
  }

  &__wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    gap: 24px;
  }

  &__container {
    flex: 1;
    padding: 32px 32px 36px;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border-radius: 2px;
    box-shadow: 0px 2px 4px 0px #14458D1A;
  }

  &__header {
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-patient-name {
      font-family: $fontFamily-NotoSans-Medium;
      font-size: $fontSize-lg;
    }

    &-item {
      margin-bottom: 4px;
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      & * {
        font-family: $fontFamily-NotoSans-Medium;
        font-size: $fontSize-md;
      }

      & span:nth-child(1) {
        color: $color-auro-metal-saurus;
      }
    }
  }

  &__menu {
    margin-bottom: 40px;
  }
}

.event-sidebar {
  flex: 1;
  max-width: 424px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: $screen-maxWidth-xl) {
  .event {
    &__wrapper {
      flex-direction: column-reverse;
    }
  }

  .event-sidebar {
    max-width: 100%;
  }
}
