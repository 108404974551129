.video-call-widget {
  padding: 24px;
  background: #262626;
  box-shadow: 0px 2px 4px 0px #22292F14;

  &__user {
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-icon {
      margin-bottom: 10px;
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-medium-slate-blue;
      border-radius: 50%;
      font-family: $fontFamily-Roboto-Medium;
      font-size: 24px;
      color: $color-white;
    }

    &-name {
      font-family: $fontFamily-Roboto-Regular;
      font-size: 18px;
      color: $color-white;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  &__reject-btn svg {
    transform: rotate(135deg);
  }
}

.video-room {
  position: relative;
  height: 320px;
  background: #262626;
  box-shadow: 0px 2px 4px 0px #22292F14;

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    &-participant {
      padding: 6px 0 0 6px;
      display: flex;
      align-items: center;
      gap: 10px;

      &-name {
        width: max-content;
        padding: 7px 17px;
        background-color: $color-black;
        font-family: $fontFamily-Roboto-Regular;
        color: $color-white;
        opacity: 0.75;
      }
    }
  }

  &__participants {
    position: relative;
    width: 100%;
    height: 100%;

    &-screensaver {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__container {
    position: absolute;
    bottom: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 16px;

    & .btn.default {
      width: 80px;
      height: 38px;
    }

    & .btn.red {
      width: 130px;
      height: 38px;
      justify-content: center;
    }
  }

  &__end-call-btn svg {
    transform: rotate(135deg);
  }

  & .audio-disabled {
    & .ant-btn-icon {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3px;
        height: 26px;
        background: rgb(52, 51, 48);
        border-right: 0.5px solid $color-white;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  & .video-disabled {
    & .ant-btn-icon {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3px;
        height: 26px;
        background: rgb(52, 51, 48);
        border-right: 0.5px solid $color-white;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}

.participant {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__screensaver {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__video {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;

    &.isEnabled {
      opacity: 1;
    }
  }
}

.local-participant {
  position: absolute;
  bottom: 54px;
  right: 26px;
  width: 80px;
  height: 80px;
  overflow: hidden;

  &__video {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;

    &.isEnabled {
      opacity: 1;
    }
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .video-room {
    height: 600px;
  }
}

@media (max-width: $screen-maxWidth-sm) {
  .video-room {
    height: 420px;
  }
}
