.timer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__current-time {
    font-family: $fontFamily-NotoSans-Medium;
    font-size: $fontSize-lg;
  }

  &__controls {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__spent-time {
    font-family: $fontFamily-NotoSans-Regular;
  }

  &__range {
    font-family: $fontFamily-NotoSans-Regular;
    color: $color-auro-metal-saurus;
  }
}
