.container {
  max-width: 1680px;
  margin: 0 auto;
  padding: 32px 0 228px;
  box-sizing: border-box;
  gap: 24px;
}

.btn {
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-family: $fontFamily-NotoSans-Regular;

  &.default {
    border-color: $color-platinum !important;
    color: $color-black !important;
  }

  &.blue {
    background: $color-curious-blue;
    border-color: $color-curious-blue !important;
    color: $color-white !important;

    & svg {
      width: 18px;
      height: 18px;
    }

    & path {
      fill: $color-white;
    }
  }

  &.orange {
    background: $color-pumpkin;
    border-color: $color-pumpkin !important;
    color: $color-white !important;
  }

  &.green {
    background: $color-green;
    border-color: $color-green !important;
    color: $color-white !important;

    & svg {
      width: 18px;
      height: 18px;
    }

    & path {
      fill: $color-white;
    }
  }

  &.red {
    background: $color-orange-red;
    border-color: $color-orange-red !important;
    color: $color-white !important;

    & svg {
      width: 18px;
      height: 18px;
    }

    & path {
      fill: $color-white;
    }
  }

  &.icon {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:disabled {
    background: $color-cultured;
    border-color: $color-platinum !important;
    color: $color-auro-metal-saurus !important;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: $color-platinum;
}

a {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: $fontFamily-Inter-Regular;
  font-size: $fontSize-sm;
  color: $color-curious-blue;
  text-decoration: underline;
  cursor: pointer !important;
}

.tooltip {
  & .ant-tooltip {
    &-arrow {
      display: none !important;
    }
  
    &-inner {
      padding: 12px !important;
      background: $color-white !important;
      border: 1px solid $color-lavender-gray;
  
      & * {
        font-family: $fontFamily-Roboto-Regular;
        font-size: $fontSize-sm;
        color: $color-roman-silver;
      }
    }
  }
}

.spin {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-shadow {
  box-shadow: 0px 2px 4px 0px #14458D1A;
}

@media (max-width: $screen-maxWidth-xxl) {
  .container {
    padding: 32px 30px 228px;
  }
}
