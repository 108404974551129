.call {
  &__voice {
    padding: 24px 32px 32px;
    background: $color-white;
    box-shadow: 0px 2px 4px 0px #22292F14;

    &-title {
      margin-bottom: 25px;
      font-family: $fontFamily-NotoSans-Medium;
      font-size: $fontSize-lg;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-phone {
      font-family: $fontFamily-Roboto-Medium;
      font-size: $fontSize-md;
    }
  }

  &__voice-active {
    padding: 24px;
    background: $color-white;
    box-shadow: 0px 2px 4px 0px #22292F14;

    &-time {
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;

      & * {
        font-family: $fontFamily-NotoSans-Medium;
        font-size: $fontSize-lg;
      }
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }

    &-end-call svg {
      transform: rotate(135deg);
    }
  }
}
