.chart {
  flex: 1;
  width: 100%;

  & .dxc-chart {
    width: 100%;
    height: 240px;
  }

  &__argument-label {
    white-space: pre;
    fill: #767676;
    font-family: "Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana, sans-serif;
    font-weight: 400;
    font-size: 14px;
    cursor: default;
  }
}
