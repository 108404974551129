.notes {
  padding: 24px 32px 32px;
  background-color: $color-white;
  box-shadow: 0px 2px 4px 0px #22292F14;

  &__spin .ant-spin-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  &__title {
    font-family: $fontFamily-Inter-Medium;
    font-size: $fontSize-lg;
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
