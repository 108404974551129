@import '../abstracts/variables';

@font-face {
  font-family: 'Inter-Regular';
  src: url('../../fonts/Inter-Regular.ttf');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('../../fonts/Inter-Medium.ttf');
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'NotoSans-Regular';
  src: url('../../fonts/NotoSans-Regular.ttf');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'NotoSans-Medium';
  src: url('../../fonts/NotoSans-Medium.ttf');
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: url('../../fonts/NotoSans-Bold.ttf');
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../fonts/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('../../fonts/Roboto-Black.ttf');
  font-weight: 900;
  font-style: normal
}

body {
  & * {
    font-size: $fontSize-sm;
  }
}

#root {
  width: 100%;
  height: 100%;
}
