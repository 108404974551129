.event-controls {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: $color-white;

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 18px;

    &-container {
      display: flex;
      align-items: center;
      gap: 18px;
    }
  }
}
