.modal {
  & .ant-modal {
    &-content {
      border-radius: 0;
    }

    &-title {
      font-family: $fontFamily-Roboto-Medium;
      font-size: $fontSize-lg;
    }
  }

  & .anticon-close {
    color: $color-black;
  }

  &__body {
    padding: 24px 0;
  }

  &__footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
  }
}

.inactivity-modal {
  &__body {
    display: flex;
    flex-direction: column;
    
    & * {
      color: $color-auro-metal-saurus;
    }
  }
}